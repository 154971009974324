<template>
  <div class="chart-plan-container">
    <div class="chart-content">
      <Row>
        <Col span="8">&nbsp;</Col>
        <Col span="8">
          <Date-picker @on-change="changeTimeOne" type="daterange" placeholder="选择日期"></Date-picker>
        </Col>
        <Col span="8">&nbsp;</Col>
      </Row>
      <div class="chart" ref="barData"></div>
    </div>
    <div class="chart-content">
      <div class="chart" ref="pieData"></div>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import echarts from 'echarts';
import chartService from '@/services/chartService';
export default {
	data() {
		return {
			searchOne: {
				start_time: '',
				end_time: '',
			},
			barChart: '',
			pieChart: '',
			xArr: [],
			yArr: [],
			pieData: [],
			branch: [],
			branchArrTwo: [],
			branchArrThree: [],
			min: '',
			max: '',
			average: '',
			median: '',
			type: '',
		};
	},
	mounted() {
		// echarts实例化
		this.pieChart = echarts.init(this.$refs.pieData);
		this.barChart = echarts.init(this.$refs.barData);
		// 初始化实例
		let barOptions = {
			title: {
				text: '方案小组完成情况',
				textStyle: {
					color: '#424e67',
					fontWeight: 'normal',
					fontSize: 14,
				},
				left: '45%',
				bottom: 40,
			},
			grid: {
				show: true,
				borderColor: '#e3e3e3',
				bottom: 80,
			},
			color: ['#f1ce7e'],
			xAxis: {
				type: 'category',
				axisLine: { onZero: false },
				data: [],
			},
			yAxis: [
				{
					type: 'value',
				},
			],
			tooltip: {
				trigger: 'axis',
				axisPointer: {
					type: 'shadow',
				},
			},
			dataZoom: [
				{
					show: true,
				},
				{
					type: 'inside',
				},
			],
			series: [
				{
					name: '数据条数',
					type: 'bar',
					data: [],
				},
			],
		};
		let pieOptions = {
			tooltip: {
				trigger: 'item',
				formatter: '{b}: {c} ({d}%)',
			},
			title: {
				text: '方案数据',
				textStyle: {
					color: '#fdad00',
					fontWeight: 'normal',
					fontSize: 28,
				},
				subtext: '统计',
				subtextStyle: {
					color: '#999',
					fontWeight: 'normal',
					fontSize: 18,
				},
				left: '49.5%',
				top: '40%',
				textAlign: 'center',
			},
			legend: {
				orient: 'vertical',
				right: 20,
				data: ['未完成', '已完成', '已调整', '待调整'],
			},
			color: ['#f1ce7e', '#66b0ea', '#f18985', '#94e1f0'],
			series: [
				{
					type: 'pie',
					clockwise: false,
					startAngle: 60,
					radius: ['50%', '75%'],
					label: {
						normal: {
							show: true,
							position: 'inside',
							formatter: '{d}%',
						},
					},
					data: [
						{ value: 0, name: '未完成' },
						{ value: 0, name: '已完成' },
						{ value: 0, name: '已调整' },
						{ value: 0, name: '待调整' },
					],
				},
			],
		};
		this.barChart.setOption(barOptions);
		this.barChart.showLoading();
		this.pieChart.setOption(pieOptions);
		this.pieChart.showLoading();
		this.getChartPlan();
	},
	methods: {
		getChartPlan() {
			chartService.getChartPlan(this.searchOne).then((data) => {
				this.xArr = [];
				this.yArr = [];
				data.data_list_team.forEach((item) => {
					this.xArr.push(item.plan_make_team);
					this.yArr.push(item.count);
				});
				this.barChart.hideLoading();
				this.barChart.setOption({
					xAxis: {
						data: this.xArr,
					},
					series: [
						{
							data: this.yArr,
						},
					],
				});

				this.pieData = [];
				data.data_list_status.forEach((item) => {
					this.pieData.push({
						value: item.count,
						name: item.status,
					});
				});
				if (this.pieData.length === 0) {
					this.pieData = [
						{ value: 0, name: '未完成' },
						{ value: 0, name: '已完成' },
						{ value: 0, name: '已调整' },
						{ value: 0, name: '待调整' },
					];
				}
				this.pieChart.hideLoading();
				this.pieChart.setOption({
					legend: {
						formatter: function (name) {
							let i = 0;
							data.data_list_status.forEach((item, index) => {
								if (item.status === name) {
									i = index;
								}
							});
							return name + '：' + data.data_list_status[i].count + ' 个';
						},
					},
					series: [
						{
							data: this.pieData,
						},
					],
				});
			});
		},
		changeTimeOne(value) {
			this.searchOne.start_time = value[0];
			this.searchOne.end_time = value[1];
			this.getChartPlan();
		},
	},
};
</script>

<style lang="css" scoped>
</style>
